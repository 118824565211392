import React from 'react';
import '../App.css';
import { Button } from './Button';
import './HeroSection.css';
import mainBackground from "./../images/group_image.jpg";

function HeroSection() {
  return (
    <div className='hero-container'>
      <img id="mainBackground" src={mainBackground} alt="main background"/>
      <h1>KADO</h1>
      <p>Kurdish American Development Organization</p>
      <p>For a better future</p>
      <div className='hero-btns'>
        
      </div>
    </div>
  );
}

export default HeroSection;
