import data from "../../data/Events";
import "./Events.css";
import { useEffect, useState } from "react";
import EventCard from "./components/EventCard";
import CardItem from "../CardItem";

export default function Events(){
    const [passedEvents, setPassedEvents] = useState([]);
    const [futureEvents, setFutureEvents] = useState([]);

    const hasPassed = (data, type)=>{
        const today = new Date();
        const inputDateStr = data.date;
        const inputDateParts = inputDateStr.split("/");
        const inputDate = new Date(inputDateParts[2], inputDateParts[0] - 1, inputDateParts[1]);
        if(type === "passed")
            return inputDate < today
        else if (type === "upcoming")
            return inputDate > today
    }
    const sortEvents = (events) => {
        return events.sort((a, b) => {
            const dateA = new Date(a.date.split("/")[2], a.date.split("/")[0] - 1, a.date.split("/")[1]);
            const dateB = new Date(b.date.split("/")[2], b.date.split("/")[0] - 1, b.date.split("/")[1]);
            return dateB - dateA; // Sort descending (latest first)
        });
    };

    useEffect(()=>{
        const passed = data.filter(data => hasPassed(data, "passed"));
        const future = data.filter(data => hasPassed(data, "upcoming"));

        setPassedEvents(sortEvents(passed));
        setFutureEvents(sortEvents(future));
    }, [])

    const display = (item)=>{
       // return <EventCard title={item.title} description={item.description}/>
       return <CardItem
              src={`images/${item.image[0]}`}
              text={item.description}
              label={item.title}
              path={"/event/"+item.id}
              date={item.date}
            />
    }
    return <section className="events d-flex justify-content-cetner flex-column align-items-center">
        <h1 className="mt-2 mb-4">We strive every day to make sure our nation progress</h1>

        <p className="mt-4">Upcoming Events</p>
        <section className="event">
            {
            futureEvents?.length > 0 ? futureEvents.map((item)=>{
                        return <section key={item.id}>
                            {display(item)}
                        </section>
                }) : <section className="no-events">
                    No upcoming events
                    </section>
            }
        </section>
        <p>Past Events</p>
        <section className="event">
            {
                passedEvents?.length > 0 ? passedEvents.map((item)=>{
                    return <section key={item.id}>
                            {display(item)}
                        </section>
                }) : <section className="no-events">
                    No passed events
                    </section>
            }
        </section>
    </section>
}