const  data = [
    {
        id : 1,
        title : "Food Distribution",
        description : "Our KADO staff had an incredibly rewarding experience volunteering with the Great Plains Food Bank. Participating in food distribution not only allowed us to contribute to the community but also reinforced our commitment to ensuring that everyone has access to nutritious food. We are deeply grateful to the Great Plains Food Bank for their collaboration and support. Together, we are making strides towards a hunger-free community. Thank you, Great Plains Food Bank, for this opportunity to serve and make a difference.",
        date : "07/17/2024",
        image : ["food_distribution_1.jpg"],
    },
    {
        id : 2,
        title : "Job Fair",
        description : "Job fair for people who are seeking new careers. Date: Tuesday June 25th, 2024- Time: 1-3 pm Location: Fargo Workforce Center 1350 32nd St S Fargo. ND 58103 KADO representaives will be available at the fair and we will be assiting anyone who want to get connected with any companies.",
        date : "06/24/2024",
        image : ["job_fair_1.jpg"],
    },
    {
        id : 3,
        title : "Housing Grant",
        description : "📢 Exciting news! The Cass Clay Community Land Trust has launched a new Homebuyer Initiated Program to help low- to moderate-income households in Clay County purchase homes. Supported by the Minnesota Housing Finance Agency, this program offers grants that don't require repayment, making homeownership more accessible. ",
        date : "07/28/2024",
        image : ["grant_1.jpg"],
    },
    {
        id : 4,
        title : "Community",
        description : "KADO was delighted to participate in the Longest Table Annual Event at M State on June 27! 🎉 This year, we had the honor of serving as a host table for Kurdish and Arabic families, bringing together our diverse community in celebration of unity and togetherness.",
        date : "06/27/2024",
        image : ["diversity_1.jpg", "diversity_1_1.jpg", "diversity_1_2.jpg", "diversity_1_3.jpg", "diversity_1_4.jpg", "diversity_1_5.jpg"],
    },
    {
        id : 5,
        title : "Community",
        description : "KADO took part in World Refugee Day at M. B. Johnson Park on June 20, 2024, representing the Kurdish Community as a panelist. Our discussion highlighted KADO's dedication to empowering Kurdish and other immigrant communities throughout the USA. We're committed to assisting individuals and families in overcoming the hurdles of adapting to a new country. This includes vital support in securing employment, preserving cultural heritage, accessing education, and facilitating connections to various resources.",
        date : "06/21/2024",
        image : ["community_1.jpg", "community_1_1.jpg", "community_1_2.jpg", "community_1_3.jpg", "community_1_4.jpg", "community_1_5.jpg", "community_1_6.jpg", "community_1_7.jpg"],
    },
    {
        id : 6,
        title : "Community",
        description : "KADO participated in the Food, Culture, and History event hosted by the Cass Clay Hunger Coalition on Thursday, June 20. Representing the Kurdish community as a panelist, we emphasized the significance of culturally relevant food within our community.",
        date : "06/20/2024",
        image : ["community_2.jpg", "community_2_1.jpg"],
    },
    {
        id : 7,
        title : "Community",
        description : "We had the pleasure of meeting with Mayor Shelly Carlson and with the Human Rights Chair Siham Amedy. The Mayor has been fantastic in working with the whole Moorhead community a truly inclusive Mayor. Siham Amedy has done a wonderful job of being the Human Rights Chair and representing the Kurdish community in such a crucial role. Thank you Madam Mayor for all you do.",
        date : "06/12/2024",
        image : ["community_3.jpg"],
    },
    {
        id : 8,
        title : "Community",
        description : "Hello all, for EID on Sunday we are going to do a community potluck picnic. We will be distributing the lambs the same day at a first come first serve basis. We hope to see you all there and present. Kurdish Community the only entry fee is a pot of delicious Kurdish food for the community to enjoy. See you Sunday!",
        date : "06/10/2024",
        image : ["community_4.jpg"],
    },
    {
        id: 9,
        title: "Culture",
        description: "We bought lambs, butchered and bagged them, and distributed them among our community members. We gathered, ate together, and celebrated the day of Eid. We thank all those who helped and supported us in making these wonderful gatherings possible. Happy Eid!",
        date: "06/16/2024",
        image : ["eid_1_2.jpg", "eid_1.jpg", "eid_1_3.jpg"],
    },
    {
        id: 10,
        title: "Business",
        description: "Yesterday our executive director attended the Global Business Connections Conference that was hosted by North Dakota Trade Office. We had the pleasure of having conversations with the Governor of North Dakota Doug Burgum, General Consular of Taiwan, and former Ambassador of China Bradstad. We also connected with our longtime partner Afro American Development  Association, director Hukun and House Rep Hamida.",
        date: "05/16/2024",
        image: ["business_1_1.jpg", "business_1.jpg", "business_1_2.jpg", "business_1_3.jpg", "business_1_4.jpg", "business_1_5.jpg", "business_1_6.jpg"],
    },
    {
        id: 11,
        title: "Community",
        description: "We're pleased to share that KADO had the privilege of joining forces with Mayor Shelly at the Vista Center for Education to meet with our incredible ESL students! 📚✨ It was an enriching and productive session where we discussed important topics and connected with our vibrant community. Together, we're helping our new community members feel understood, supported, and empowered as they start their journey in our community.",
        date: "05/09/2024",
        image: ["community_5.jpg"]
    },
    {
        id: 12,
        title: "Development",
        description: "“We are so proud to be apart of the creation of Kurdish language classes in Moorhead Schools. It’s one of my most proud collaborative efforts that I’ve been apart of in my life. Zak is our amazing Kurdish teacher in MAPS and our program manager at Kurdish American Development Organization” —Director Kawar Farok",
        date: "02/26/2024",
        image: ["development.jpg"]
    },
    {
        id: 13,
        title: "Award",
        description: "Zakaria Amin joined KADO 3 1/2 years ago and his passion for the community, cultural, and the human rights of all people always showed. His fire always burns deep when it comes to his passion of helping people. We at KADO congratulate Zak for his continued example of excellence and doing all he can for his community. Congratulations on your reception on the Moorheart award.",
        date: "12/14/2023",
        image: ["award_1.jpg"]
    },
    {
        id: 14,
        title: "Business",
        description: "Today one of our local Kurdish businesses, Jeylan’s Sweets wanted us to connect them with law enforcement and the fire station to be able to give them a donation of baklava. We were happy to be apart of their good deed and connect more businesses in our community to our men and women in uniform. They had our director give on their behalf to the fire station. Fantastic day for the community.",
        date: "08/21/2023",
        image: ["business_2.jpg", "business_2_1.jpg", "business_2_2.jpg", "business_2_3.jpg"],
    },
    {
        id: 15,
        title: "Food Distribution",
        description: "Today we met a team from the Great Pains Food Pantry regarding reinforcing our partnership on providing food to the community. The Great Plains Food Pantry added another staff member, Heather Martinez, as a SNAP Outreach Coordinator helping needy people with SNAP, know as food stamp, application at the North Dakota side.",
        date: "08/03/2023",
        image: ["food_distribution_2.jpg", "food_distribution_2_1.jpg", "food_distribution_2_2.jpg"],
    },
    {
        id: 16,
        title: "Development",
        description: "Today our executive director had the pleasure of meeting Minnesota house Representative Jim Joy. We discussed community, business, workforce, and how we all can work together. Thank you Representative Jim Joy.",
        date: "07/18/2023",
        image: ["development_1.jpg"],
    },
    {
        id: 17,
        title: "Development",
        description: "Had a great meeting with state Senator Rob Kupec. Talked about ways we can collaborate to make a better and more inclusive Minnesota. Thanks for the meeting today Senator.",
        date: "07/10/2023",
        image : ["development_2.jpg"],
    },
    {
        id: 18,
        title: "Culture",
        description: "Here are some of our high schoolers celebrating international day at Moorhead Senior High School. Even though a lot of our student couldn’t attend, the ones that did really represented our people well. They did a great job representing our history, food, and culture. You made us proud beyond belief. The Kurdish future is bright with this generation. Well done!! Our Executive Director said, “He was very proud of the youth of our community and excited about the future.”",
        date: "05/18/2023",
        image : ["culture_1.jpg"]
    },
    {
        id: 19,
        title: "Business",
        description: "Our community was privileged to have two very knowledgeable individuals educate our business leaders on how to grow their companies and realize their full potential. Special thanks to Dr Rachel Backes Lundbohm, Dr James Leiman and the University of Minnesota Crookston, for putting this together for us. The follow up class with be held on 5/20/2023.",
        date: "05/13/2023",
        image: ["business_3.jpg", "business_3_1.jpg", "business_3_2.jpg"]
    },
    {
        id: 20,
        title: "Food Distribution",
        description: "Thanks to Great Plains Food Bank we were able to serve about 100 households in the matter of 2 hours. We thank them for their partnership and this great project of cultural foods. Big thank you to our KADO team.",
        date: "04/15/2023",
        image: ["food_distribution_3.jpg"]
    },
    {
        id: 21,
        title: "Community",
        description: "Big thank you to Moorhead Area Public Schools for their continued efforts on cultural awareness of communities in Moorhead. Kurds are the largest minority population of Moorhead and thank MAPS for their continued partnership.",
        date: "04/06/2023",
        image: ["community_6.jpg", "community_6_1.jpg", "community_6_2.jpg", "community_6_3.jpg"]
    },
    {
        id: 22,
        title: "Newroz",
        description: "It was a very successful Newroz event. We had a full Fargodome. Many thanks to all the supporters and our community members for making it so successful. We can plan and host any event, but without attendees it is pointless.",
        date: "03/20/2023",
        image: ["newroz_2023_1.jpg", "newroz_2023_2.jpg", "newroz_2023_3.jpg", "newroz_2023_4.jpg", "newroz_2023_5.jpg", "newroz_2023_6.jpg", "newroz_2023_7.jpg", "newroz_2023_8.jpg", "newroz_2023_9.jpg", "newroz_2023_10.jpg", "newroz_2023_11.jpg", "newroz_2023_12.jpg", "newroz_2023_13.jpg", "newroz_2023_14.jpg", "newroz_2023_15.jpg", "newroz_2023_16.jpg", "newroz_2023_17.jpg"]
    },
    {
        id: 23,
        title: "Community",
        description: "To combat depression and promote good health, we organized a community game night on Saturday. We spent quality time with our loved ones, strengthening our relationships and fostering a sense of safety and support during challenging times.",
        date: "01/27/2024",
        image: ["gamenight_1.jpg", "gamenight_1_1.jpg", "gamenight_1_2.jpg", "gamenight_1_3.jpg", "gamenight_1_4.jpg", "gamenight_1_5.jpg", "gamenight_1_6.jpg"]
    }
    // To fight depression and health, we had game night.
    // North dakota trade office. World business event
]

export default data