import React from 'react';
import Navbar from './components/Navbar';
import './App.css';
import Home from './components/pages/Home';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Mission from './components/pages/Mission';
import Vision from './components/pages/Vision';
import Events from './components/pages/Events';
import About from './components/pages/About';
import Contact from './components/pages/Contact';
import Donation from './components/pages/Donation';
import Gallery from './components/pages/Gallery';
import EventDetail from './components/pages/EventDetail';

function App() {
  return (
    <>
      <Router>
        <Navbar />
        <Routes>
          <Route path='/' exact element={<Home/>} />
          <Route path="/event/:id" element={<EventDetail/>}/>
          <Route path='/mission' element={<Mission/>} />
          <Route path='/vision' element={<Vision/>} />
          <Route path="/events" element={<Events/>}/>
          <Route path="/aboutus" element={<About/>}/>
          <Route path="/contact" element={<Contact/>}/>
          <Route path="/donation" element={<Donation/>}/>
          <Route path="/gallery" element={<Gallery/>}/>
        </Routes>
      </Router>
    </>
  );
}

export default App;
