import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import data from "../../data/Events";

export default function EventDetail() {
    const { id } = useParams();
    const [post, setPost] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        try {
            const parsedInt = parseInt(id, 10);
            if (isNaN(parsedInt)) {
                throw new Error("Incorrect post: ID is not a valid number");
            }

            const filtered = data.find(item => item.id === parsedInt);
            if (!filtered) {
                throw new Error("Post not found");
            }

            setPost(filtered);
        } catch (e) {
            setError(e.message);
            console.error(e.message);
        }
    }, [id]);

    if (error) {
        return <section><p>{error}</p></section>;
    }

    if (!post) {
        return <section><p>Loading...</p></section>;
    }

    return (
        <section>
            {/* Bootstrap Carousel */}
            <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel" style={{width: "40dvw", margin: "1em auto"}}>
                <div className="carousel-inner">
                    {post.image.map((filename, index) => (
                        <div key={index} className={`carousel-item ${index === 0 ? 'active' : ''}`}>
                            <img src={`/images/${filename}`} className="d-block w-100" alt={`Slide ${index + 1}`} style={{objectFit: "cover", maxHeight:"600px"}} />
                        </div>
                    ))}
                </div>
                <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button>
            </div>

            {/* Event Details */}
            <h1>Event Detail</h1>
            <section style={{width:"60dvw", margin: "0 auto"}}>
                <p>Date: {post.date}</p>
                <p>Description: {post.description}</p>
            </section>
            {/* Display other properties of the post here */}
        </section>
    );
}
