import React from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';
import logo from "./../images/logo.png"

function Footer() {
  const thisyear = new Date().getFullYear()
  return (
    <div className='footer-container'>
      <div class='footer-links'>
        <div className='footer-link-wrapper'>
          <div class='footer-link-items'>
            <h3 className='text-white text-decoration-underline mb-md-4'>About Us</h3>
            <Link to='/mission'>Mission</Link>
            <Link to='/vision'>Vission</Link>
            <Link to='/events'>Events</Link>
            <Link to='/gallery'>Gallery</Link>
          </div>
          <div class='footer-link-items'>
            <h3 className='text-white text-decoration-underline mb-md-4'>Contact Us</h3>
            <Link to='/contact'>Contact</Link>
            <Link to='/'>Support</Link>
            <Link to='https://www.google.com/maps?ll=46.848553,-96.722988&z=16&t=m&hl=en&gl=US&mapclient=embed&q=2777+34th+St+S+Moorhead,+MN+56560' target='_blank'>Destinations</Link>
            <Link to='/'>Sponsorships</Link>
          </div>
        </div>
        <div className='footer-link-wrapper'>
          <div class='footer-link-items'>
            <h3 className='text-white text-decoration-underline mb-md-4'>Videos</h3>
            <Link to='/'>Submit Video</Link>
            <Link to='/'>Ambassadors</Link>
            <Link to='/'>Agency</Link>
            <Link to='/'>Influencer</Link>
          </div>
          <div class='footer-link-items'>
            <h3 className='text-white text-decoration-underline mb-md-4'>Social Media</h3>
            <Link to='/'>Instagram</Link>
            <Link to='/'>Facebook</Link>
            <Link to='/'>Youtube</Link>
            <Link to='/'>Twitter</Link>
          </div>
        </div>
      </div>
      <section class='social-media'>
        <div class='social-media-wrap'>
          <div class='footer-logo'>
            <Link to='/' className='social-logo'>
              KADO
              <img src={logo} alt="logo" style={{height:"60px"}}/>
            </Link>
          </div>
          <small class='website-rights'>KADO © {thisyear}</small>
          <div class='social-icons'>
            <Link
              class='social-icon-link facebook'
              to='/'
              target='_blank'
              aria-label='Facebook'
            >
              <i class='fab fa-facebook-f' />
            </Link>
            <Link
              class='social-icon-link instagram'
              to='/'
              target='_blank'
              aria-label='Instagram'
            >
              <i class='fab fa-instagram' />
            </Link>
            <Link
              class='social-icon-link youtube'
              to='/'
              target='_blank'
              aria-label='Youtube'
            >
              <i class='fab fa-youtube' />
            </Link>
            <Link
              class='social-icon-link twitter'
              to='/'
              target='_blank'
              aria-label='Twitter'
            >
              <i class='fab fa-twitter' />
            </Link>
            <Link
              class='social-icon-link twitter'
              to='/'
              target='_blank'
              aria-label='LinkedIn'
            >
              <i class='fab fa-linkedin' />
            </Link>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Footer;
