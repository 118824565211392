import React from 'react';
import '../../App.css';
import logo from "./../../images/img-1.jpg";

export default function Vision() {
  return <section className='vision'>
    <img alt="logo" src={logo}/>
    <section className='dark-layer'></section>
    <h1>Vision</h1>
    <p>To make sure that all community members reach their full potential and become contributing members of society and economy.</p>
    </section>;
}
