import "./Contact.css";
import logo from "./../../images/img-3.jpg";
import { useEffect, useState } from "react";
import Server from "./../../Server";
import Swal from "sweetalert2";
import loader from "./../../images/motion-blur-2.svg"

export default function Contact(){

    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        email : "",
        subject : "",
        message : "",
        key: "SendingEmail"
    })

    useEffect(()=>{
        var textarea = document.getElementById("message");
        textarea.addEventListener("keyup", ()=>{
            var length = textarea.value.length
            try{
                var counter = document.getElementById("count");
                counter.innerHTML = `${length} of 1200`;
            }catch{
                console.error("ERROR CODE: #1");
            }
        })
    },[])

    const sendContact = (e)=>{
        e.preventDefault()
        try{
            var textarea = document.getElementById("message")
            if(textarea.value.length == 0 && formData.email.length == 0 && formData.subject == 0){
                Swal.fire({
                    icon: "error",
                    title: "Incomplete form",
                    text: "Please fill out the whole form"
                  });
                throw ("Err")
            }else{
                setLoading(true);
                Server.post("/index.php", {...formData, "message": textarea.value}).then(res=>{
                    setLoading(false);
                    Swal.fire({
                        icon: "success",
                        title: "Success",
                        text: "Your email has been send successfully"
                    });
                    setFormData({
                        email : "",
                        subject : "",
                        message : "",
                        key: "SendingEmail"
                    })
                    textarea.value = ""
                    
                }).catch(err=>{
                    throw "Email not send"
                })
                
            }
        }catch{
            console.error("ERROR CODE: #2")
        }
    }

    const handleChanger = (e) =>{
        const { name, value } = e.target
        setFormData({...formData, [name]: value})
    }
    return <section className="contact">
        <img src={logo} alt="contact-logo"/>
        <section className="dark-layer"></section>
        <section className="loader" style={{display: !loading ? "none": "flex"}}>
            <img src={loader} alt="loading"/>
        </section>
        <section className="contact-form">
            <h1>Contact us</h1>
            <form className="form-control p-4">
                <section className="form-floating">
                    <input name="email" id="email" type="email" className="form-control" placeholder="i" required onChange={handleChanger} value={formData.email}/>
                    <label htmlFor="email">Email</label>
                </section>

                <section className="form-floating mt-4">
                    <input name="subject" id="subject" type="text" className="form-control" placeholder="i" required onChange={handleChanger} value={formData.subject}/>
                    <label htmlFor="subject">Subject</label>
                </section>

                <section className="form-floating mt-4">
                    <textarea id="message" type="text" className="form-control" placeholder="i" maxLength={1200} required></textarea>
                    <label htmlFor="message">Message</label>
                </section>

                <section id="count" className="float-end text-white mt-1">
                    0 of 1200
                </section>

                <button className="btn btn-success rounded mt-4" type="submti" onClick={sendContact}>Send</button>
            </form>
        </section>
        </section>
}