import React from 'react';
import '../../App.css';
import logo from "./../../images/img-2.jpg";

export default function Mission() {
  return <section className='mission'>
    <img alt="logo" src={logo}/>
    <section className='dark-layer'></section>
    <h1>Mission</h1>
    <p>To be an advisor and assistant to the people in our community, so we can provide them the necessary resources they need to excel in America.</p>
    </section>;
}
