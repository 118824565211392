import "./Newroz.css"
import { Link } from "react-router-dom"
export default function Newroz(){
    return (
        <section className="newroz">
            <section>
                <h1 className="pt-3">Annual Newroz Event</h1>
                <section>
                    <Link to="https://www.youtube.com/watch?v=_be29xPzHyo" target="_blank">
                        <img src="/images/newroz_2024_1.jpg" alt="Newroz Event"/>
                    </Link>
                    <p>Newroz 2024</p>
                </section>
            </section>
        </section>
    )
}