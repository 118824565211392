import "./About.css"
import kawar from "./../../images/Kawar-1.png"

export default function About(){
    return (
        <section className="about">
            <section className="mt-3 mb-3">
                <img src={kawar} alt="kawar farok"/>
            </section>
            <h1>Kawar Farok - Director of KADO</h1>

            <p>
            Kawar Farok currently serves as the Director of the Kurdish-American Development Organization (KADO), a nonprofit organization dedicated to addressing workforce development issues in North Dakota and Minnesota. In his role, Kawar plays a pivotal part in day-to-day operations and serves as a connector to clients and partners. He demonstrates a unique ability to diagnose challenges faced by businesses and provides strategic solutions to guide them toward success. Bringing a wealth of experiences and a passionate commitment to community service, Kawar is a valuable asset to KADO.
            </p>

            <p>
            Kawar’s journey began in Kurdistan, and in 1992, he, along with his family, immigrated to the U.S. to escape persecution under Saddam Hussein. Raised in Fargo/Moorhead, his early experiences involved providing translation services for his family, instilling in him the values of active listening and proposing effective solutions. Transitioning into adulthood, he pursued a degree in Political Science at MSUM. Early in his career, Kawar dedicated eight years to the banking sector, progressing to roles such as AVP of Banking, Loan Officer, and leading the development of the online lending team.
            </p>

            <p>
            Fueled by a passion for community service, Kawar founded KADO in 2020, focusing on workforce development. His commitment to connecting businesses with the right individuals for their workforce needs became evident during his role as Executive Director.
            </p>

            <p>
            Before taking on his current role, Kawar served as a Relationship Executive specializing in Workforce Development and Recruitment. He played a crucial role in educating company executives on the workforce shortage issue and the strategic significance of hiring international workers.
            </p>

            <p>
            Kawar is not only a dedicated professional but also a devoted husband and father to two children. Outside of work, he finds joy in participating in sports through recreational leagues and immerses himself in the excitement of Sunday football games.
            </p>

            <h3>Expertise/Offerings:</h3>
            <ul>
                <li>Growing & Scaling Businesses</li>
                <li>Business Development</li>
                <li>Community Building</li>
                <li>Workforce Development & Attraction</li>
                <li>Government Affairs & Policy</li>
                <li>Speaking</li>
                <li>Fundraising</li>
            </ul>

            <h3>My Big WHY:</h3>
            <p>
            At the core of my professional drive is helping businesses find solutions to their problems. I am driven by the purpose of bridging gaps, fostering growth, and facilitating strategic solutions for businesses navigating the ever-evolving landscape. My dedication to business development aligns seamlessly with my overarching goal – making a positive impact by addressing the pressing issues faced by organizations and contributing to a more resilient and thriving business ecosystem.
            </p>
        </section>
    )
}