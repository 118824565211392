// src/components/Gallery.js

import React from 'react';
import './Gallery.css';

const images = [
  './../images/community_1_1.jpg',
  './../images/food_distribution_1.jpg',
  './../images/community_1_2.jpg',
  './../images/community_1_3.jpg',
  './../images/community_1_4.jpg',
  './../images/community_1_5.jpg',
  './../images/community_1_6.jpg',
  './../images/community_1_7.jpg',
  './../images/community_1_8.jpg',
  './../images/community_1.jpg',
  './../images/community_2.jpg',
  './../images/community_2_1.jpg',
  './../images/community_3.jpg',
  './../images/community_4.jpg',
  './../images/diversity_1_1.jpg',
  './../images/diversity_1_2.jpg',
  './../images/diversity_1_3.jpg',
  './../images/diversity_1_4.jpg',
  './../images/diversity_1_5.jpg',
  './../images/eid_1.jpg',
  './../images/eid_1_2.jpg',
  './../images/eid_1_3.jpg',
  './../images/eid_1_4.jpg',
  './../images/eid_1_5.jpg',
  './../images/eid_1_6.jpg',
  './../images/eid_1_7.jpg',
  './../images/eid_1_8.jpg',
  './../images/eid_1_13.jpg',
  './../images/eid_1_14.jpg',
  './../images/eid_1_15.jpg',
  './../images/eid_1_16.jpg',
  '/videos/eid.mp4',
  '/videos/culture_1.mp4',
  './../images/food_distribution_2.jpg',
  "./../images/food_distribution_2_1.jpg",
  "./../images/business_3.jpg",
  "./../images/business_3_1.jpg"
];

const Gallery = () => {
    return (
      <div className="gallery">
        {images.map((src, index) => (
          <div key={index} className="gallery-item">
            {
              src.includes(".mp4") ? <video src={src} alt={`Gallery item ${index}`} controls></video> : <img src={src} alt={`Gallery item ${index}`} />
            }
      
          </div>
        ))}
      </div>
    );
  };

export default Gallery;
